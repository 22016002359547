.beers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 5px;
    
    .beer {
        display: flex;
        align-items: flex-start;
        margin-right: 20px;
        margin-bottom: 20px;

        h3 {
            font-family: 'Paytone One', sans-serif;
            font-size: 20px;
            font-weight: lighter;
            text-transform: uppercase;
            margin: 0px;
            color: #000000;
        }

        img {
            width: 120px;
            padding-right: 10px;
        }

        .abv {
            margin: 0;
            color: #8d4f10;
        }
    }
}